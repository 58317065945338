import type { ImageNodeData } from '@common/studio-types';
import { ImageInput, UploadedImage } from '@maestro/components';
import { dimensions } from '@maestro/styles';
import styled from 'styled-components';
import { Hint } from '../../components/Hint';
import { StudioNodeFormComponent } from '../Node.types';

export const ImageForm: StudioNodeFormComponent<ImageNodeData> = ({
  data,
  onChange,
}) => {
  const onUpload = (result?: UploadedImage) => {
    onChange({
      ...data,
      isGenerating: result ? data.isGenerating : false,
      imageUrl: result?.path ?? '',
      width: result?.width ?? 0,
      height: result?.height ?? 0,
    });
  };

  return (
    <Container>
      <Hint>Add some imagery to your story</Hint>
      <ImageInput
        value={data.imageUrl}
        uploadPath="episodes"
        onChange={onUpload}
      />
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.size8};
  width: 100%;
`;
